@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
    font-family: Epilogue !important;
    user-select: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Remove input number arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

select {
    border: 1px solid;
    border-color: rgb(207 207 207 / 1);
    border-radius: 4px !important;
    background-color: white !important;
    outline: none !important;
    padding: 0.75rem !important;
    font-size: 0.875rem !important;
    color: black;
}

select:disabled {
    opacity: 1 !important;
    cursor: not-allowed !important;
    background-color: rgb(207 207 207 / 1) !important;
    color: rgb(87 87 87 / 1) !important;
}

input[type=radio] {
    background-image: none !important;
}